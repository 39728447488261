import { FC, memo } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';
import InternalLink from '@components/InternalLink';
import * as styles from './styles';

type MarkdownSectionProps = {
  markdownContent?: MDXRemoteSerializeResult;
};

const components = { InternalLink };

const MarkdownSection: FC<MarkdownSectionProps> = ({ markdownContent }) =>
  markdownContent ? (
    <div className={styles.prose} data-test-id="markdown-content-block">
      <HydrateMdx mdx={markdownContent} components={components} />
    </div>
  ) : null;

export default memo(MarkdownSection);
