import { classnames } from '@tailwindcss-classnames';

export const prose = classnames(
  'prose',
  'm-auto',
  'w-11/12',
  'lg:max-w-4xl',
  'md:px-6'
);

export default prose;
